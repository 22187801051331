import React from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Anime from "react-anime"

// Import Css
// import "react-tabs/style/react-tabs.css";

import HeaderServices from "../../components/header_services"

export default function ClientJourney() {
  const heroTitle = "Client Journey"
  return (
    <HeaderServices heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <div class="journey--steps journey--steps-sm">
        <Tabs class="journey--steps-tabs" id="JourneyTab">
          <TabList>
            <Tab>1</Tab>
            <Tab>2</Tab>
            <Tab>3</Tab>
            <Tab>4</Tab>
            <Tab>5</Tab>
          </TabList>

          <TabPanel>
            <Anime
              easing="easeOutExpo"
              duration={1000}
              direction="alternate"
              loop={false}
              delay={(el, index) => index * 100}
              translateX={[50, 0]}
              opacity={[0, 1]}
            >
              <h3>1. Initial consultation</h3>
              <p>We will:</p>
              <ul>
                <li>
                Explore your specific wealth management needs, your investment goals and
                objectives
                </li>
                <li>Discuss your experience of financial wealth management</li>
                <li>Talk about how we work, the process and propose the next steps</li>
              </ul>
            </Anime>
          </TabPanel>
          
          <TabPanel>
            <Anime
              easing="easeOutExpo"
              duration={1000}
              direction="alternate"
              loop={false}
              delay={(el, index) => index * 100}
              translateX={[50, 0]}
              opacity={[0, 1]}
            >
              <h3>2. Fact finding</h3>
              <p>
              We will discuss your current financial position in detail, including your income,
              expenditure, assets and liabilities. Understand your return expectations and risk
              tolerance levels. This will provide us with a comprehensive view of your
              circumstances and allow us to focus on designing a strategy and plan to address your
              objectives.
              </p>
            </Anime>
          </TabPanel>
          
          <TabPanel>
            <Anime
              easing="easeOutExpo"
              duration={1000}
              direction="alternate"
              loop={false}
              delay={(el, index) => index * 100}
              translateX={[50, 0]}
              opacity={[0, 1]}
            >
              <h3>3. Analysis, research and recommendations</h3>
              <p>
              Now we will have all the information we need to assess your existing plan and how
              you can meet your aspirations or goals. We will come with a holistic plan and
              recommend various investment options and solutions that will meet your needs. Key
              will be to come to a mutual understanding in relation to an appropriate path for
              your investment journey.
              </p>
            </Anime>
          </TabPanel>
          
          <TabPanel>
            <Anime
              easing="easeOutExpo"
              duration={1000}
              direction="alternate"
              loop={false}
              delay={(el, index) => index * 100}
              translateX={[50, 0]}
              opacity={[0, 1]}
            >
              <h3>4. Implementation</h3>
              <p>
              Once you are comfortable with the plans and investment options that we have
              recommended, the next stage is to implement your plans and complete the applications
              for the recommended solutions. We assist with execution in the most cost efficient
              manner to maximise the return outcome on an after fee basis.
              </p>
            </Anime>
          </TabPanel>
          
          <TabPanel>
            <Anime
              easing="easeOutExpo"
              duration={1000}
              direction="alternate"
              loop={false}
              delay={(el, index) => index * 100}
              translateX={[50, 0]}
              opacity={[0, 1]}
            >
              <h3>5. Review (for clients with on-going advice)</h3>
              <p>
              We will review your plans with you and the progress towards your goals. The impact
              of any changes in your life and the new goals and adjustments to your plan will be
              reviewed to ensure the work we do together remains relevant and in-line with your
              expectations.
              </p>
            </Anime>
          </TabPanel>
        </Tabs>
      </div>
    </HeaderServices>
  )
}
